document.querySelectorAll('.wp-block-video').forEach((el) => {
    //console.log(el)
    if(!el.firstChild.getAttribute('autoplay')){
        el.firstChild.removeAttribute('controls')
        let VideoPlaySplash = document.createElement('div');
        VideoPlaySplash.addEventListener('click',(el)=>{
            el.target.parentElement.firstChild.setAttribute('controls','true')
            el.target.parentElement.firstChild.play();
            el.target.classList.add('wp-block-video--clicked')
        })
        el.append(VideoPlaySplash);
    }
});
